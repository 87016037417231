@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

* {
    font-family: 'Rubik', sans-serif !important;
}

html {
    scroll-behavior: smooth !important;
}

a {
    text-decoration: none;
    color: inherit
}

body {
    overflow-x: hidden !important;
    width: 100vw !important;
}

.MuiButton-startIcon {
    margin: 0 !important;
}